import { Slick } from './_slick';

export const ProductSlider = {
  init: () => {
    const slickOptions = Slick.getDefaultOptions();

    $('.product-slider').each((_index, slider) => {
      const $slider = $(slider);

      Object.assign(slickOptions, {
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [{
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        }, {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        }],
      });

      if ($slider.hasClass('fix-height')) {
        const fixSlideOut = ($slideOut) => {
          $slideOut
            .data('originalHeight', $slideOut.outerHeight())
            .css('height', 'auto')
            .data('normalHeight', $slideOut.outerHeight())
            .height($slideOut.data('originalHeight'))
            .stop()
            .animate({
              height: $slideOut.data('normalHeight'),
            }, 250);
        };

        $slider.on('init', () => {
          const $slideOut = $slider.closest('.container-related-products');
          fixSlideOut($slideOut);
        });

        $slider.find('img').each((index, image) => {
          const tmpImage = image;

          tmpImage.onload = () => {
            const $slideOut = $(image).closest('.container-related-products');
            fixSlideOut($slideOut);
          };
        });
      }

      $slider.slick(slickOptions);
    });
  },
};

export default ProductSlider;
