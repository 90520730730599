import { ClickScroll } from '../module/_clickscroll';

export const DachcomAccordion = {
  init: () => {
    $('.toolbox-accordion .accordion').on('shown.bs.collapse', (event) => {
      ClickScroll.scrollTo($(event.currentTarget).find('.accordion-toggle:not(.collapsed)'));
    });
  },
};

export default DachcomAccordion;
