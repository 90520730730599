import 'jquery-match-height';

let identifier = 0;

export const MatchHeight = {
  init: (parentSelector, itemSelector) => {
    $(parentSelector).each((i, parent) => {
      const $elements = $(parent).find(itemSelector);
      $elements.attr('data-mh', `auto-matchheight-${identifier}`);
      $elements.matchHeight();

      identifier += 1;
    });
  },
};

export default MatchHeight;
