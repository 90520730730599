export const JobApplication = {
  init: () => {
    const $modal = $('#modal-application');

    if ($modal.length === 1) {
      const $privacyStatmentCheckboxes = $('#accept-job-privacy-statement-checkbox', $modal);
      if ($privacyStatmentCheckboxes.length === 1) {
        $('[data-application-modal]').click(() => {
          $modal.modal('show');
        });
        const $privacyStatmentCheckbox = $($privacyStatmentCheckboxes[0]);
        const $jobApplyClickInterceptors = $('.job-apply-click-interceptor', $modal);
        const $interceptedLinks = $('a', $jobApplyClickInterceptors);
        $privacyStatmentCheckbox.on('change', () => {
          if ($privacyStatmentCheckbox.is(':checked') === true) {
            $privacyStatmentCheckbox.removeClass('is-invalid');
          }
        });
        $interceptedLinks.click((e) => {
          if (!$privacyStatmentCheckbox.is(':checked')) {
            e.preventDefault();
            $privacyStatmentCheckbox.addClass('is-invalid');
          }
        });
      }
    }
  },
};

export default JobApplication;
