import * as SineWaves from 'sine-waves';
import gsap from 'gsap';

const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');

  return (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./));
};

const initWaves = (element) => {
  const wrappingSlide = $(element).closest('.toolbox-custom-stage-content');

  // eslint-disable-next-line no-new
  new SineWaves({
    el: element,
    speed: 10,
    width: () => $(wrappingSlide).width(),
    height: () => 533,
    ease: 'SineInOut',
    wavesWidth: '100%',

    waves: [{
      timeModifier: 0.1,
      lineWidth: 1,
      amplitude: 90,
      wavelength: 600,
    }, {
      timeModifier: 0.1,
      lineWidth: 1,
      amplitude: 100,
      wavelength: 605,
    }, {
      timeModifier: 0.1,
      lineWidth: 1,
      amplitude: 110,
      wavelength: 610,
    }, {
      timeModifier: 0.1,
      lineWidth: 1,
      amplitude: 120,
      wavelength: 615,
    }, {
      timeModifier: 0.1,
      lineWidth: 1,
      amplitude: 130,
      wavelength: 620,
    }, {
      timeModifier: 0.1,
      lineWidth: 1,
      amplitude: 140,
      wavelength: 625,
    }, {
      timeModifier: 0.1,
      lineWidth: 1,
      amplitude: 150,
      wavelength: 630,
    }, {
      timeModifier: 0.1,
      lineWidth: 1,
      amplitude: 160,
      wavelength: 635,
    }],

    // Called on window resize
    resizeEvent() {
      let gradient = this.ctx.createLinearGradient(0, 0, this.width, 0);
      gradient.addColorStop(0, 'rgba(48, 148, 180, 0.1)');
      gradient.addColorStop(0.5, 'rgba(255, 255, 255, 0.2)');
      gradient.addColorStop(1, 'rgba(48, 148, 180, 0.1)');

      let index = 0;
      let { length } = this.waves;
      while (index < length) {
        this.waves[index].strokeStyle = gradient;
        index += 1;
      }

      // Clean Up
      index = undefined;
      length = undefined;
      gradient = undefined;
    },
  });
};

export const Stage = {
  init: () => {
    const $canvas = $('canvas.stage-waves');

    if ($canvas.length) {
      initWaves($canvas[0]);
    }

    gsap.to('.hotspot-image', {
      duration: 2,
      opacity: 0.5,
      ease: 'none',
      repeat: -1,
      yoyo: true,
    });

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    if (!isMobile && !isIE()) {
      $('.webgl-desktop-visibility').show();
      $('.webgl-mobile-visibility').hide();
    } else {
      $('.webgl-mobile-visibility').show();
      $('.webgl-desktop-visibility').hide();

      const $modal = $('#modal-stage');
      $('[data-stage-modal]').click(() => {
        $modal.modal('show');
      });
    }
  },
};

export default Stage;
