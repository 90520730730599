export const ShareButtons = {
  update: () => {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    $('.whatsapp-list-item').each((i, whatsappListItem) => {
      const $whatsappListItem = $(whatsappListItem);
      if (isMobile) {
        $whatsappListItem.show();
      }
    });
  },
};

export default ShareButtons;
