import * as SineWaves from 'sine-waves';
import gsap from 'gsap';

import { Slick } from './_slick';

const initWaves = (element) => {
  const wrappingSlide = $(element).closest('.toolbox-custom-stage-slide');

  // eslint-disable-next-line no-new
  new SineWaves({
    el: element,
    speed: 10,
    width: () => $(wrappingSlide).width(),
    height: () => 533,
    ease: 'SineInOut',
    wavesWidth: '100%',

    waves: [{
      timeModifier: 0.1,
      lineWidth: 1,
      amplitude: 90,
      wavelength: 600,
    }, {
      timeModifier: 0.1,
      lineWidth: 1,
      amplitude: 100,
      wavelength: 605,
    }, {
      timeModifier: 0.1,
      lineWidth: 1,
      amplitude: 110,
      wavelength: 610,
    }, {
      timeModifier: 0.1,
      lineWidth: 1,
      amplitude: 120,
      wavelength: 615,
    }, {
      timeModifier: 0.1,
      lineWidth: 1,
      amplitude: 130,
      wavelength: 620,
    }, {
      timeModifier: 0.1,
      lineWidth: 1,
      amplitude: 140,
      wavelength: 625,
    }, {
      timeModifier: 0.1,
      lineWidth: 1,
      amplitude: 150,
      wavelength: 630,
    }, {
      timeModifier: 0.1,
      lineWidth: 1,
      amplitude: 160,
      wavelength: 635,
    }],

    // Called on window resize
    resizeEvent() {
      let gradient = this.ctx.createLinearGradient(0, 0, this.width, 0);
      gradient.addColorStop(0, 'rgba(48, 148, 180, 0.1)');
      gradient.addColorStop(0.5, 'rgba(255, 255, 255, 0.2)');
      gradient.addColorStop(1, 'rgba(48, 148, 180, 0.1)');

      let index = 0;
      let { length } = this.waves;
      while (index < length) {
        this.waves[index].strokeStyle = gradient;
        index += 1;
      }

      // Clean Up
      index = undefined;
      length = undefined;
      gradient = undefined;
    },
  });
};

export const StageSlider = {
  init: () => {
    const slickOptions = Slick.getDefaultOptions();

    $('.blur-text').each((ix, el) => {
      const $text = $(el);
      const oldText = $text.text();

      $text.empty();

      oldText.split('').forEach((letter) => {
        $text.append(`<span class="blur-letter">${letter}</span>`);
      });
    });

    const slideAnimationFn = () => {
      // remove all wave-canvas no matter what.. we'll add them back, where and when we need them
      $('canvas.slide-waves').remove();

      const $currentSlide = $('.toolbox-custom-stage-slide.slick-current');
      const $closestSlideWrapper = $currentSlide.closest('.toolbox-custom-stage-slide');

      const animation = $closestSlideWrapper.data('animation');
      if (animation) {
        const $canvasElement = $('<canvas class="waves slide-waves"></canvas>');
        $canvasElement.prependTo($currentSlide);

        initWaves($canvasElement[0]);
      } else if ($closestSlideWrapper.hasClass('blur-animation')) {
        const blurAnimationTimeline = $closestSlideWrapper.data('blurAnimation');

        const $blurLetters = $('span.blur-letter', $closestSlideWrapper);

        if (!blurAnimationTimeline) {
          const tl = gsap.timeline();

          tl.addLabel('start');

          tl.to('.blur-overlay', {
            duration: 1.2,
            opacity: 0,
            ease: 'none',
          }, 'start');

          tl.addLabel('endOverlay');

          tl.to($blurLetters, {
            duration: 1.2,
            opacity: 1,
            ease: 'none',
            stagger: 0.1,
          }, 'start');

          tl.to('.blur-circle', {
            duration: 2,
            rotation: 180,
            ease: 'none',
          }, 'endOverlay');

          tl.to('.slide-img-blured', {
            duration: 2.5,
            opacity: 0,
            ease: 'none',
          }, 'endOverlay');

          $closestSlideWrapper.data('blurAnimation', tl);
        } else {
          blurAnimationTimeline.play();
        }
      }
    };

    const $slider = $('.toolbox-custom-stage-slider');

    Object.assign(slickOptions, {
      infinite: true,
      prevArrow: '<button class="btn btn-slide btn-slide-primary btn-prev"><i class="swi swi-chevron-left"></i></button>',
      nextArrow: '<button class="btn btn-slide btn-slide-primary btn-next"><i class="swi swi-chevron-right"></i></button>',
      autoplay: $slider.data('autoplay') === true,
      autoplaySpeed: 10000,
    });

    const slickSlider = $slider.slick(slickOptions);

    // afterChange seems to trigger together with setPosition,
    // one should be enough and setPosition is also triggered on resize
    // slickSlider.on("afterChange", slideAnimationFn);
    slickSlider.on('setPosition', slideAnimationFn);
    slickSlider.on('init', slideAnimationFn);

    slickSlider.on('beforeChange', () => {
      const $currentSlide = $('.toolbox-custom-stage-slide.slick-current');
      const $closestSlideWrapper = $currentSlide.closest('.toolbox-custom-stage-slide');

      const blurAnimation = $closestSlideWrapper.data('blurAnimation');
      if (blurAnimation) {
        blurAnimation.progress(0).pause();
        $closestSlideWrapper.data('blurAnimation', blurAnimation);
      }
    });
  },
};

export default StageSlider;
