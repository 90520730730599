import { EditMode } from '../util/_editmode';

export const LightGallery = {
  init: () => {
    if (EditMode.isEnabled()) {
      return;
    }

    $('.light-box').each((i, lightbox) => {
      const $lightbox = $(lightbox);
      const selector = $lightbox.data('selector');
      let options;

      if (selector) {
        options = { selector };
      }

      $lightbox.lightGallery(options);
    });
  },
};

export default LightGallery;
