export const DachcomToolbox = {
  init: () => {
    $.toolboxCore({
      googleMapsHandler: {
        enabled: true,
        selector: '.toolbox-googlemap',
        config: {
          centerMapOnResize: true,
          theme: 'bootstrap4',
        },
      },
    });
  },
};

export default DachcomToolbox;
