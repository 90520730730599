export const ImageTeaser = {
  init: () => {
    const headlineSelector = 'h1, h2, h3, h4, h5, h6, .like-h1, .like-h2, .like-h3, .like-h4, .like-h5, .like-h6';

    $('.image-teaser').each((i, teaser) => {
      const $teaser = $(teaser);
      const $teaserHeadline = $teaser.find('.teaser-text').find(headlineSelector);
      const teaserLink = $teaser.find($('.image-teaser-link')).attr('href');

      if (teaserLink) {
        $teaserHeadline.wrap(`<a href="${teaserLink}"></a>`);
      }
    });
  },
};

export default ImageTeaser;
