export const ClickScroll = {
  init: () => {
    $('a.clickscroll[href^="#"]').click((e) => {
      const href = $(e.currentTarget).attr('href');
      ClickScroll.scrollTo($(href));
      return false;
    });
  },

  getWindowAnchor: () => {
    const windowHash = window.location.hash;
    if (windowHash && windowHash.length > 1) {
      return windowHash.substring(1);
    }
    return false;
  },

  handlePageUrlAnchor: () => {
    const windowAnchor = ClickScroll.getWindowAnchor();
    if (windowAnchor) {
      setTimeout(() => {
        const $scrollAnchor = $(`a[id=${windowAnchor}]`).first();
        const autoClickElement = $(`*[data-page-anchor-auto-click=${windowAnchor}]`).first();
        ClickScroll.scrollTo($scrollAnchor);
        if (autoClickElement) {
          setTimeout(() => {
            if (autoClickElement.data('page-anchor-auto-click-disabled')) {
              // In some cases the click must be disabed, thus this data attribute
            } else {
              autoClickElement.click();
            }
          }, 500);
        }
      }, 1);
    }
  },

  scrollTo: ($element, addOffset = 0) => {
    const $html = $('html, body');
    let topOffset = 0;

    try {
      const $anchor = $element;
      const $headerNavBar = $('.header-navbar').first();

      topOffset = $anchor.length ? $anchor.offset().top : 0;

      if ($headerNavBar) {
        topOffset -= $headerNavBar.outerHeight();
      }
    } catch (ignore) {
      // Do nothing
    }

    let scrollToOffsetPostion = topOffset + addOffset;
    if (scrollToOffsetPostion < 0) {
      scrollToOffsetPostion = 0;
    }

    $html.animate({
      scrollTop: scrollToOffsetPostion,
    });

    return false;
  },
};

export default ClickScroll;
