import './_polyfills';
import 'jquery';
import 'waypoints/lib/jquery.waypoints';

import 'bootstrap';
import 'sweetalert2';
import 'slick-carousel';
import 'lightgallery';
import 'gsap';
import 'file-upload-with-preview';
import 'select2';
import 'video.js';

import '../public/vendor/dachcom/toolbox/frontend/plugins/jquery.tb.core';
import '../public/vendor/dachcom/toolbox/frontend/plugins/jquery.tb.ext.google-maps';
import '../public/vendor/dachcom/toolbox/frontend/plugins/jquery.tb.ext.video';
import '../public/vendor/dachcom/toolbox/frontend/plugins/jquery.tb.ext.iframe';
import '../public/vendor/dachcom/toolbox/frontend/plugins/jquery.tb.ext.googleOptOutLink';

import 'fine-uploader/jquery.fine-uploader/fine-uploader-new.css';
import 'fine-uploader/jquery.fine-uploader/jquery.fine-uploader';

// The original jquery.fb.core.form-builder.js lib does no handle exceptions at all,
// That is why we replace it with a version which fires an event in this case.
import '../public/vendor/dachcom/jquery.fb.core.form-builder-fix-failure';
import '../public/vendor/dachcom/formbuilder/frontend/plugins/jquery.fb.ext.conditional-logic';
import '../public/vendor/dachcom/formbuilder/frontend/vendor/dependsOn/dependsOn.min';

// Put your global app JS here
import { DachcomAccordion } from './dachcom/_accordion';
import { DachcomToolbox } from './dachcom/_toolbox';
import { DachcomFormBuilder } from './dachcom/_formbuilder';
import { Animations } from './module/_animations';
import { ClickScroll } from './module/_clickscroll';
import { FormBuilder } from './module/_formbuilder';
import { Header } from './module/_header';
import { ImageTeaser } from './module/_image-teaser';
import { LightGallery } from './module/_lightgallery';
import { Select2 } from './module/_select2';
import { ShareButtons } from './module/_sharebuttons';
import { Slick } from './module/_slick';
import { Stage } from './module/_stage';
import { MatchHeight } from './module/_matchheight';
import { MyPageHeaderSlider } from './module/_mypage-header-slider';
import { ProductAreas } from './module/_product-areas';
import { ProductSlider } from './module/_productslider';
import { Tooltip } from './module/_tooltip';
import { JobApplication } from './module/_job-application';
import { StageSlider } from './module/_stage-slider';

$.fn.select2.defaults.set('theme', 'bootstrap');

$(() => {
  DachcomAccordion.init();
  DachcomToolbox.init();
  DachcomFormBuilder.init();
  Animations.init();
  ClickScroll.init();
  FormBuilder.init();
  Header.init();
  ImageTeaser.init();
  LightGallery.init();
  Select2.init();
  ShareButtons.update();
  Slick.init();
  Stage.init();
  MatchHeight.init('.equal-height', '.equal-height-item');
  MyPageHeaderSlider.init();
  ProductAreas.init();
  ProductSlider.init();
  Tooltip.init();
  JobApplication.init();
  StageSlider.init();

  ClickScroll.handlePageUrlAnchor();
});
