import { Slick } from './_slick';

export const MyPageHeaderSlider = {
  init: () => {
    const slickOptions = Slick.getDeviceOptions();

    $('.mypage-header-slider').each((_index, slider) => {
      const $slider = $(slider);

      Object.assign(slickOptions, {
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      });

      $slider.slick(slickOptions);
    });
  },
};

export default MyPageHeaderSlider;
