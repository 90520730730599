import gsap from 'gsap';

export const Header = {
  init: () => {
    const $body = $('body');
    const $navbar = $('.header-navbar');
    const $logo = $('.navbar-brand');
    const $activeDropdownToggle = $('.dropdown-toggle.active');
    const $activeVisibleDropdownMenu = $activeDropdownToggle.siblings($('.dropdown-menu.show'));
    const $navItemActive = $('.nav-item.active.dropdown');
    let mobileIsOpen = false;

    // Header docking logic
    const scrolledToTop = () => window.pageYOffset === 0;
    const anyDropdownOpened = () => $('.header-navbar .dropdown.show').length > 0;

    const changeLogo = (logoData) => {
      $logo.css({
        backgroundImage: `url("${$logo.data(logoData)}")`,
      });
    };

    const isDark = $body.hasClass('with-dark-navbar');
    let isDocked = true;

    const setHeaderDocked = () => {
      isDocked = true;
      $navbar.addClass('navbar-docked');

      if (isDark) {
        if (!mobileIsOpen) {
          changeLogo('img-dark');
          $navbar.addClass('navbar-dark');
          $navbar.removeClass('navbar-light');
        }
      } else {
        changeLogo('img-light');
        $navbar.addClass('navbar-light');
        $navbar.removeClass('navbar-dark');
      }
    };

    const setHeaderUndocked = () => {
      isDocked = false;
      changeLogo('img-light');
      $navbar.removeClass('navbar-docked navbar-dark');
      $navbar.addClass('navbar-light');
    };

    const updateHeader = (forceUpdate) => {
      const setDocked = scrolledToTop() && !anyDropdownOpened();
      const noChanges = (isDocked && setDocked) || (!isDocked && !setDocked);

      if (!forceUpdate && noChanges) {
        return;
      }

      if (setDocked) {
        setHeaderDocked();
      } else {
        setHeaderUndocked();
      }
    };

    $(window).scroll(() => updateHeader());
    updateHeader(true);

    // Search button logic
    const $searchForm = $navbar.find('.search-form');
    const $searchInput = $searchForm.find('.search-input');
    const $searchButton = $searchForm.find('.search-button');
    const $clearButton = $searchForm.find('.clear-button');
    const $navbarMenuContainer = $navbar.find('.navbar-menu-container');
    const $searchFormDummy = $navbar.find('.search-form-dummy');

    let lastHandledState = null;

    const IsMobile = () => ($(document).width() <= 991);

    // make this happen everytime initially on mobile
    if (IsMobile()) {
      // makes nav fullwidth
      $navbar.addClass('mobile');
      $logo.addClass('mobile');

      // removes no-line class set in else branch
      $('.nav-item.dropdown').not('.active').find($('.nav-link.dropdown-toggle')).removeClass('no-line');

      // makes all relevant items visible
      $activeDropdownToggle.attr('aria-expanded', true);
      $('.dropdown-toggle').attr('aria-expanded', true);
      $activeVisibleDropdownMenu.addClass('show');
      $navItemActive.addClass('show');
      $('.mega-dropdown.active').find($('.mega-menu')).addClass('show');
      $('.sub-dropdown-toggle.active').siblings($('.dropdown-submenu')).addClass('show');
      $navItemActive.find($('.dropdown-toggle')).addClass('show');
    } else {
      // makes nav default width
      $navbar.removeClass('mobile');
      $logo.removeClass('mobile');

      // removes class show on dropdown-menu to disable the dropped down view on desktop
      $('.nav-item.dropdown').find($('.dropdown-menu')).removeClass('show');

      // adds no-line class to disable underline of selected menu-item (except active one)
      $('.nav-item.dropdown').not('.active').find($('.nav-link.dropdown-toggle')).addClass('no-line');

      // makes all relevant items invisible
      if ($activeDropdownToggle.attr('aria-expanded', true)) {
        $activeDropdownToggle.attr('aria-expanded', false);
        $activeDropdownToggle.siblings($('.dropdown-menu.show')).removeClass('show');
        $('.dropdown-toggle').attr('aria-expanded', false);
        $navItemActive.find($('.dropdown-toggle')).removeClass('show');
        $('.nav-item.dropdown.show').removeClass('show');
        $navItemActive.find($('.dropdown-menu')).removeClass('show');
      }
    }

    // make this happen everytime the viewport is resized to mobile size
    $(window).resize(() => {
      setTimeout(() => {
        const isMobile = IsMobile();

        if (isMobile !== lastHandledState) {
          if (isMobile) {
            // makes nav fullwidth
            $navbar.addClass('mobile');
            $logo.addClass('mobile');

            // removes no-line class set in else branch
            $('.nav-item.dropdown').not('.active').find($('.nav-link.dropdown-toggle')).removeClass('no-line');

            // makes all relevant items visible
            $activeDropdownToggle.attr('aria-expanded', true);
            $('.dropdown-toggle').attr('aria-expanded', true);
            $activeVisibleDropdownMenu.addClass('show');
            $navItemActive.addClass('show');
            $('.mega-dropdown.active').find($('.mega-menu')).addClass('show');
            $('.sub-dropdown-toggle.active').siblings($('.dropdown-submenu')).addClass('show');
            $navItemActive.find($('.dropdown-toggle')).addClass('show');
          } else {
            // makes nav default width
            $navbar.removeClass('mobile');
            $logo.removeClass('mobile');

            // removes class show on dropdown-menu to disable the dropped down view on desktop
            $('.nav-item.dropdown').find($('.dropdown-menu')).removeClass('show');

            // adds no-line class to disable underline of selected menu-item (except active one)
            $('.nav-item.dropdown').not('.active').find($('.nav-link.dropdown-toggle')).addClass('no-line');

            // makes all relevant items invisible
            if ($activeDropdownToggle.attr('aria-expanded', true)) {
              $activeDropdownToggle.attr('aria-expanded', false);
              $activeDropdownToggle.siblings($('.dropdown-menu.show')).removeClass('show');
              $('.dropdown-toggle').attr('aria-expanded', false);
              $navItemActive.find($('.dropdown-toggle')).removeClass('show');
              $('.nav-item.dropdown.show').removeClass('show');
              $navItemActive.find($('.dropdown-menu')).removeClass('show');
            }
          }
          lastHandledState = isMobile;
        }
      }, 300);
    });

    const hideSearchBarAnimation = () => {
      gsap.to($searchForm, {
        duration: 0.3,
        width: $searchFormDummy.width(),
        ease: 'none',
        clearProps: 'all',
      });
      gsap.to($navbarMenuContainer, {
        duration: 0.3,
        opacity: 1,
        ease: 'none',
        clearProps: 'all',
        onComplete: () => {
          $searchFormDummy.css({
            width: 0,
            display: 'none',
          });
          $searchForm.removeClass('show');
        },
      });
    };

    const showSearchBarAnimation = () => {
      gsap.to($searchForm[0], {
        duration: 0.3,
        width: '75%',
        ease: 'none',
      });
      gsap.to($navbarMenuContainer, {
        duration: 0.3,
        opacity: 0,
        ease: 'none',
      });
    };

    const hideSearch = () => {
      if (!IsMobile()) {
        hideSearchBarAnimation();
      } else {
        $navbarMenuContainer.addClass('show');
        $searchForm.removeClass('show');
      }
    };

    const showSearch = () => {
      if (!IsMobile()) {
        // Get corrct distance to right border
        const $rightNav = $navbar.find('.right-nav');
        $searchForm.css('right', $rightNav.width() + 15);

        $searchFormDummy.css({
          width: $searchForm.width(),
          display: 'block',
        });
        $searchForm.addClass('show');
        showSearchBarAnimation();
        $searchInput.focus();
      } else {
        $navbarMenuContainer.removeClass('show');
        $searchForm.addClass('show');
        $searchInput.focus();
      }
    };

    $searchForm.submit(() => {
      if (!$searchInput.val()) {
        return false;
      }

      return undefined;
    });

    $searchButton.click(() => {
      if ($searchForm.hasClass('show')) {
        hideSearch();
      } else {
        showSearch();
      }
    });

    $clearButton.click(() => {
      $searchInput.val('');
      hideSearch();
    });

    const $submenus = $('.dropdown-submenu');
    const $submenuToggles = $('.sub-dropdown-toggle');
    const $dropdowns = $('.header-navbar .dropdown');

    $submenuToggles.click((e) => {
      const $toggle = $(e.currentTarget);
      const $dropdownSubmenu = $toggle.next('.dropdown-submenu');
      const isCollapsed = $toggle.attr('aria-expanded') === 'false';

      $dropdownSubmenu.toggleClass('show');
      $toggle.attr('aria-expanded', isCollapsed);

      $submenus.not($dropdownSubmenu).removeClass('show');
      $submenuToggles.not($toggle).attr('aria-expanded', false);

      return false;
    });
    $dropdowns
      .on('shown.bs.dropdown hidden.bs.dropdown', () => {
        $submenus.removeClass('show');
        $submenuToggles.attr('aria-expanded', false);
        $activeDropdownToggle.removeClass('active');
      })
      .on('shown.bs.dropdown', () => {
        $('#flyout-overlay').remove();
        $('<div id="flyout-overlay" />').insertBefore($navbar);
        $navbar.addClass('flyout-open');
      })
      .on('hidden.bs.dropdown', () => {
        $('#flyout-overlay').remove();
        $navbar.removeClass('flyout-open');
      });

    // update submenus and header in mobile view, no scroll on body, if open
    $('.navbar-toggler').click((e) => {
      if ($(e.currentTarget).attr('aria-expanded') === 'false') {
        $body.addClass('no-scroll');
        mobileIsOpen = true;
        setHeaderUndocked();
        // simulates click on the nav items to open them and adds visibility classes
        setTimeout(() => {
          $activeDropdownToggle.click();
          $('.sub-dropdown-toggle.active').click();
          $navItemActive.addClass('show');
          $('.mega-dropdown.active').find($('.mega-menu')).addClass('show');
          $('.dropdown-toggle.show').siblings($('.dropdown-menu')).addClass('show');
        }, 50);
      } else {
        setTimeout(() => {
          $body.removeClass('no-scroll');
          mobileIsOpen = false;
          updateHeader(true);
        }, 450);
      }
    });

    // mouseover sets header light, mouseout resets header color
    $navbar.on('mouseover', () => setHeaderUndocked());
    $navbar.on('mouseout', () => updateHeader(true));
  },
};

export default Header;
