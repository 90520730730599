import { Lang } from '../util/_lang';
import { ClickScroll } from './_clickscroll';

export const FormBuilder = {
  init: () => {
    const $formEmail = $("[name^='formbuilder_']").filter("[name$='[email]']");
    $formEmail.attr('type', 'email');

    $('.formbuilder').each((_index, form) => {
      const $form = $(form);

      const elementContainer = (className) => $form.find(`.${className}`).closest('.default');

      // Adds breaks to form groups
      const $formBreaker = $('<div class="form-breaker" />');
      const $formBreakerWithSpacer = $('<div class="form-breaker mt-space-2" />');

      const autoFillRequestUrlFields = $form.find('input.auto_fill_request_url_field');
      autoFillRequestUrlFields.each((idx, el) => {
        const $el = $(el);
        $el.val(`${window.location.protocol}//${window.location.host}${window.location.pathname}`);
      });

      $formBreakerWithSpacer.insertBefore(elementContainer('form-break-with-space-before'));
      $formBreaker.insertBefore(elementContainer('form-break-before'));
      $formBreaker.insertAfter(elementContainer('form-break-after'));

      // Sets fullwidth class to form groups
      elementContainer('form-make-fullwidth').addClass('form-fullwidth');
      elementContainer('form-make-halfwidth').addClass('form-halfwidth');
      elementContainer('form-make-quarterwidth').addClass('form-quarterwidth');
      elementContainer('form-make-thirdwidth').addClass('form-thirdwidth');
      elementContainer('form-make-fifthwidth').addClass('form-fifthwidth');
      elementContainer('form-make-twofifthswidth').addClass('form-twofifthswidth');
      elementContainer('form-make-threefifthswidth').addClass('form-threefifthswidth');

      // Sets language to current language, if available
      const langCode = Lang.getCurrentCode();
      $form.find('select.form-make-currentlang').each((index, langSelect) => {
        const $langSelect = $(langSelect);
        const $langOption = $langSelect.find(`option[value="${langCode}"]`);

        if ($langOption.length) {
          $langSelect.val(langCode).trigger('change');
        }
      });

      // Handles success messages, if set
      $form.on('formbuilder.success', (e, messages, redirect) => {
        if (redirect) {
          window.location.href = redirect;
          return;
        }

        if (messages && messages.length && messages[0].message && messages[0].type === 'success') {
          const $formWrapper = $form.closest('.form-builder-wrapper');
          const $formButton = $('.form-button');
          const $formHint = $('.mandatory-hint');

          $(`<div class="success-response-msg alert alert-success">${messages[0].message}</div>`).insertAfter($formWrapper);

          const $formBuilderWrapper = $('.form-builder-wrapper');

          if (!$formBuilderWrapper.parent().hasClass('modal-body')) {
            ClickScroll.scrollTo($formBuilderWrapper, -180);
          }

          $formWrapper.hide();
          $formButton.hide();
          $formHint.hide();
        }
      });
    });

    $('[data-submit-form], form.ajax-form button[type="submit"]').click((e) => {
      const $button = $(e.currentTarget);
      const formSelector = $button.data('submit-form');
      const $form = formSelector ? $(formSelector).first() : $button.closest('form');
      $form.find('div.checkbox-switch .invalid-feedback').addClass('invalid-feedback-to-remove-fix'); // somehow the checkbox-switch messages are not automatically-deleted

      const showLoading = () => {
        $button.prop('disabled', true);
        $button.data('loading-html', $button.html());
        $button.prepend('<i class="swi swi-spinning swi-replay" />');
      };

      const hideLoading = () => {
        $button.prop('disabled', false);
        $button.html($button.data('loading-html'));
      };

      /**
       *
       * @param event
       */
      const onErrorFieldOnce = (event) => {
        const $currentForm = $(event.currentTarget);
        hideLoading();
        const $select = $('.select2-init', $currentForm);
        const $selection = $select.closest('.form-group').find('.select2-selection');
        $selection.each((idx, el) => {
          const $currentSelect = $(el);
          if (!$currentSelect.hasClass('no-value')) {
            // Make valid(=filled) checkboxes green
            $currentSelect.addClass('is-valid');
          }
        });

        $currentForm.find('div.checkbox-switch .invalid-feedback-to-remove-fix').remove(); // Fix duplicate message problem
      };

      const handleNonRecoverableError = () => {
        const $formhandlerErrorMessageContainers = $('.formhandler-error-message-container');
        $formhandlerErrorMessageContainers.addClass('is-form-fatal-error');
        if ($formhandlerErrorMessageContainers.length) {
          // Error Message implemented on form page,
          // we hide the form because an error message can be shown.
          hideLoading();
          $form.hide();
          $('.hide-on-formbuilder-non-recoverable-error').hide();
          ClickScroll.scrollTo($formhandlerErrorMessageContainers);
        }
      };

      const handleAjaxError = (event, xhr/* , status, text , $formOrigin */) => {
        const $formhandlerErrorMessageContainersForError = $('.formhandler-error-message-container');
        $formhandlerErrorMessageContainersForError.addClass('is-form-fatal-error');
        if ($formhandlerErrorMessageContainersForError.length) {
          try {
            if (xhr.responseJSON) {
              const responseData = xhr.responseJSON;
              if (responseData.data && responseData.data.formbuilder_errormessage_raw) {
                $formhandlerErrorMessageContainersForError.html(
                  responseData.data.formbuilder_errormessage_raw,
                );
              }
            }
          } catch (err) {
            // Do nothing
          }
        }
        handleNonRecoverableError();
      };

      if ($form.length) {
        showLoading();
        $form.one('formbuilder.success', hideLoading);
        $form.one('formbuilder.error', handleNonRecoverableError);
        $form.one('formbuilder.error-field', onErrorFieldOnce);
        $form.one('formbuilder.ajaxerror', handleAjaxError);
        $form.submit();
      }

      return false;
    });
  },

  registerEvents: ($form) => {
    const eventInitializer = {
      onSubmit: (fn) => {
        $form.on('submit', fn);
        return eventInitializer;
      },

      onSuccess: (fn) => {
        $form.on('formbuilder.success', fn);
        return eventInitializer;
      },

      onError: (fn) => {
        $form.on('formbuilder.error', fn);
        return eventInitializer;
      },

      onErrorField: (fn) => {
        $form.on('formbuilder.error-field', fn);
        return eventInitializer;
      },

      onResponse: (fn) => {
        $form.on('formbuilder.success formbuilder.error formbuilder.error-field', fn);
        return eventInitializer;
      },
    };

    return eventInitializer;
  },

  setModalSubmitOnce: ($modal) => {
    const $modalFooter = $modal.find('.modal-footer');
    const $footerHint = $modal.find('.modal-footer-hint');
    const $footerButtons = $modalFooter.find('.btn');
    const $form = $modal.find('form.ajax-form');
    let $cancelButton;

    let mailSent = false;
    $modal.on('hidden.bs.modal', () => {
      if (mailSent) {
        // FIXME: Refresh on modal close if mail was sent,
        //   FormBuilder currently can't send multiple times
        window.location.reload();
      }
    });

    FormBuilder.registerEvents($form)
      .onSubmit(() => {
        $cancelButton = $modalFooter.find('.cancel-button').detach();
      })
      .onResponse(() => {
        $modalFooter.prepend($cancelButton);
      })
      .onSuccess(() => {
        // Hide all submit buttons, show success close button
        $footerButtons.hide();
        $footerHint.hide();
        mailSent = true;
      });
  },
};

export default FormBuilder;
