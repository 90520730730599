import { EditMode } from '../util/_editmode';

export const Slick = {
  getDefaultOptions: () => {
    const isEditMode = EditMode.isEnabled();

    return {
      rows: 0,
      accessibility: !isEditMode,
      infinite: !isEditMode,
      prevArrow: '<button class="btn btn-slide btn-prev"><i class="swi swi-chevron-left"></i></button>',
      nextArrow: '<button class="btn btn-slide btn-next"><i class="swi swi-chevron-right"></i></button>',
    };
  },

  getDeviceOptions: () => {
    const isEditMode = EditMode.isEnabled();

    return {
      rows: 0,
      accessibility: !isEditMode,
      infinite: !isEditMode,
      prevArrow: '<button class="btn btn-slide btn-slide-primary btn-prev"><i class="swi swi-chevron-left"></i></button>',
      nextArrow: '<button class="btn btn-slide btn-slide-primary btn-next"><i class="swi swi-chevron-right"></i></button>',
    };
  },

  init: () => {
    $('.slick-slider').slick(Slick.getDefaultOptions());
  },
};

export default Slick;
