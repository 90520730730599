export const Select2 = {
  init: () => {
    const $selects = $('select.select2-init');

    $selects.each((i, select) => {
      const $select = $(select);

      if ($select.hasClass('no-value')) {
        $select.val('');
      }

      let placeholder = $select.data('placeholder');
      if (!placeholder) {
        placeholder = $select.attr('placeholder') || undefined;
      }

      const $closestModal = $select.closest('.modal');
      const isInModal = $closestModal.length && $closestModal.find($select).length;

      const options = {
        placeholder,
        allowClear: $select.hasClass('allow-clear'),
        minimumResultsForSearch: $select.hasClass('no-search') ? Infinity : undefined,
        width: $select.hasClass('fill-width') ? '100%' : undefined,
      };
      if (isInModal) {
        options.dropdownParent = $closestModal;
      }
      $select.select2(options);
    });

    $selects.on('select2:select', (event) => {
      const $current = $(event.currentTarget);
      const $selection = $current.closest('.form-group').find('.select2-selection');
      if ($current.hasClass('is-invalid')) {
        $current.removeClass('no-value');
        $current.removeClass('is-invalid');
        $selection.addClass('is-valid');
      }
    });

    $selects.on('select2:unselecting', (event) => {
      const $current = $(event.currentTarget);
      const $selection = $current.closest('.form-group').find('.select2-selection');

      $selection.removeClass('is-valid');
      $current.addClass('no-value');
      $current.addClass('is-invalid');
    });
  },
};

export default Select2;
