import 'jquery-match-height';

export const ProductAreas = {
  init: () => {
    const $allAreas = $('.product-area');

    const getSliderForArea = ($area) => $(`.product-area-slider[data-slider="${$area.data('target')}"]`);

    const fixPositioning = ($slider) => $slider.find('.product-slider').slick('setPosition');

    const expandAreas = ($areas) => {
      $areas.each((i, area) => {
        const $area = $(area);
        const $slider = getSliderForArea($area);

        $area.addClass('is-expanded');
        $slider.addClass('is-shown');

        fixPositioning($slider);
      });
    };

    const contractAreas = ($areas) => {
      $areas.each((i, area) => {
        const $area = $(area);
        const $slider = getSliderForArea($area);

        $area.removeClass('is-expanded');
        $slider.removeClass('is-shown');
      });
    };

    $('.product-area-link').click((e) => {
      const $button = $(e.currentTarget);
      const $area = $button.closest('.product-area');

      contractAreas($allAreas.not($area));

      if ($area.hasClass('is-expanded')) {
        contractAreas($area);
      } else {
        expandAreas($area);
      }

      return false;
    });

    // Same height init
    $(window).resize(() => {
      if ($(document).width() >= 768) {
        $('.toolbox-custom-product-areas').each((i, brick) => {
          $(brick).find('.product-area-sameheight').matchHeight({
            byRow: false,
          });
        });
      }
    });

    $('.close-slideout').click(() => {
      contractAreas($allAreas);
    });
  },
};

export default ProductAreas;
